const Strings_En = {
    Aikido:"Aikido",	
    AikidoSince	:"Aikido in Beer Sheva since 1993",	
    AssistantInstructor: "Assistant Instructor",	
    BeerShevaAikikai: "Beer Sheva Aikikai",	
    BerkeleyMemberOf: "Un En Kai is directly connected to #0# (Aikikai Foundation), Tokyo, Japan.",
    ByEmail: "by email",
    ByPhone: "by telephone",
    ChiefInstructor: "Chief Instructor",
    ContactUs: "Contact Us",
    Dan: "Dan",
    Dojo: "Dojo",
    DojoText_1: "Classes are held in the #0# in Beer Sheva.",
    EitanBehar: "Eitan Behar",
    EitanAddress: "(Mail) c/o Mr. E. Behar, 18 Shikma Street, Beersheba 8412721, ISRAEL",
    Email: "Email",
    FounderSupervisor: "Founder/Supervisor",
    Gallery: "Gallery",
    Ilana: "Ilana",
    Info: "Info",
    InfoText_1: "For registration information please contact the sports center office",
    InfoText_2: "For specific information about the dojo activities and seminars please contact the dojo's secretary",
    Instructor: "Instructor",
    Kids: "Kids",
    KidsClass: "Kids Class",
    LessonsTaught: "All lessons are taught by",
    Location: "Location",
    MemberOf_1: "Beer Sheva Aikikai is a member dojo of",
    MemberOf_2:	"headquartered in Berkeley California.",
    Membership:	"Membership",
    Mixed: "Mixed",
    Or: "or",
    OvadiaZagri: "Ovadia Zagri",
    OvadiaZagriAddress: "(Mail) 4/3 Nahal Gov Street, Beersheba, 8485784, ISRAEL",
    QualifiedInstructor: "qualified  aikido instructor",
    QualifiedInstructor_1: "qualified  aikido instructor",
    Schedule: "Schedule",
    Seminars: "Seminars",
    SenseiAddress: "Mailing address: 37 Zin St. Meitar 85025, Israel",
    Shidoin: "Shidoin",
    SigiRaveh: "Sigi Raveh",
    SportsCenterBenGurion: "Sports Center, Ben Gurion University",
    SportsCenter: "Sports Center",
    Seminar: "Seminar",
    SummerSeminar: "Summer Seminar",
    SummerSeminar2020: "UnEnKai Summer Seminar will be June 18 – 21, 2020 (Thursday – Sunday) Contact Beer Sheva Aikikai for information",
    WinterSeminar2022: "UnEnKai Seminar will be February 19 – 20, 2022 (Saturday – Sunday) Contact Beer Sheva Aikikai for information, And additionaly please check the web-site or call/email to confirm current guidelines at the Dojo for vaccine status and for masks.",
    Sunday: "Sunday",
    Tel: "Tel.",
    Thursday: "Thursday",
    Tuesday: "Tuesday",
    YossiHatzorSensei: "Yossi Hatzor Sensei",
}
export { Strings_En }